import { notify_error, notify_error_Message } from './notification.service'

/**
 * Manage Error Anzeigen mittels Notification
 *
 * @param e
 */
export const error = (e: any) => {
    try {
        if (localStorage.getItem('token')) {
            if (!e.response) {
                if (e.msg) {
                    notify_error_Message(e.msg)
                    return
                } else {
                    notify_error()
                    return
                }
            }
            if (e.response.hasOwnProperty('data')) {
                if (e.response.data.hasOwnProperty('code')) {
                    if (e.response.data.code === 'token_not_valid') {
                        localStorage.removeItem('token')
                        window.location.reload()
                    } else {
                        notify_error_Message(e.response.data.msg)
                    }
                } else {
                    notify_error_Message(e.response.msg)
                }
            } else {
                console.log(e)
                notify_error()
            }
        }
    } catch {
        notify_error()
    }
}

export const errorFetch = (e: any) => {
    error(e)
}
