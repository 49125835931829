import React from "react";
import './ribbon.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SidebarItems from "../../templates/default/config/SidebarItems";
import {Link} from "react-router-dom";
import ReactDOM from "react-dom";

const Ribbon = (props: { setValue: any; value: any,elemets?:any,setFavi:any }) => {

    let RibbonsTab: JSX.Element[] = []

    let RibbonsLenght = SidebarItems(props.value).length;

    for(let x=0;x<RibbonsLenght;x++){
        let Item = SidebarItems(props.value)[x];
        RibbonsTab.push(
            <Col style={{
                margin: '10px', padding: '10px', marginRight: '10px', textAlign: 'center'
            }}>
                <div className="outer-border-box boxShadow">
                    <div className="label-source-box">
                        <span className="box-title">{Item.header} </span>
                        <div className="box-contents">
                            {buildRibonsIcon(Item.menuitem, props.setFavi)}
                        </div>
                    </div>
                </div>


            </Col>
        )
    }





    return (<>
            <div id={'modal'}>
                <div
                    id={'dialogRibbon'}
                    role="dialog"
                    aria-modal="true"
                    className="fade modal show"
                >
                    <div className="modal-dialog modal-fullscreen">
                        <div
                            className="modal-content"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() =>
                                        closeModalRibbon('dialogRibbon', 'imgHolder')
                                    }
                                />
                            </div>
                            <div className="modal-body"  style={{ padding: '0px 12px 12px  12px' }}>
                                <Row>
                                {RibbonsTab}
                                {props.elemets}
                                </Row>
                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Ribbon;


const buildRibonsIcon = (icons:any,setFavi:any) => {

    let setIcon: JSX.Element[] = []
    let Favos = [];


    for(let x=0;x<icons.length;x++){

        let star;
        star =  (
            <>
                <span id={icons[x].icon} className="material-icons" aria-hidden="true" style={{color:'white',position: 'absolute',
                    top: '5px',right: '5px'}} title={icons[x].name} onClick={()=> SetFavo(icons[x],setFavi)}>star_border</span>
            </>
        )
        if (
            localStorage.getItem('Favo') !== null
        ) {
            Favos = JSON.parse(
                localStorage.getItem('Favo') as string
            )
        for(let f=0; f<Favos.length; f++){

            if(Favos[f].name === icons[x].name) {
                star =  (
                    <>
                <span id={icons[x].icon} className="material-icons" aria-hidden="true" style={{color:'gold',position: 'absolute',
                    top: '5px',right: '5px'}} title={icons[x].name} onClick={()=> DeleteFavo(icons[x],setFavi)}>star</span>
                    </>
                )
            }
            }

        }



        setIcon.push(
            <>
                <Col className={'boxShadow'} style={{marginRight:'10px',marginLeft:'10px',width:'auto',textAlign:'center',border: '1px solid gray',borderRadius:'15px',background:'rgba(19, 94, 126, 1)',color:'white',
                    position: 'relative'
                }}>
            <Link
                key={x}
                to={icons[x].route}
                className={'text-white text-decoration-none'}
                onClick={() =>
                    closeModalRibbon('dialogRibbon', 'imgHolder')
                }
            ><p  style={{marginTop:'5px'}}>
                <span  className="material-icons" aria-hidden="true" style={{fontSize: '65px',verticalAlign: 'bottom',marginRight:'5px',color:'white'}} title={icons[x].name}>{icons[x].icon}</span>
                <br/>{icons[x].name}


            </p>
            </Link>
                    <span id={'Holder'+icons[x].name.replace(/ /g,'')}> {star}</span>

                </Col>
            </>
        )
    }

    return(
        <>
            <Row  style={{width:'max-content'}}>
            {setIcon}
            </Row>
        </>
    )


}


export  const closeModalRibbon = (id: any, idsecond: any) => {
    let eles = document.getElementById(id) as HTMLDivElement
    eles.style.display = 'none'
    let ele = document.getElementById(idsecond) as HTMLDivElement
    ele.innerHTML = ''
    let eletags = document.getElementById('tags') as HTMLDivElement
    eletags.innerHTML = ''
}

export const  SetFavo = (Favonew:any,setFavi:any) => {
    let Favos = [];
    let createFavo;
    if (
        localStorage.getItem('Favo') !== null
    ) {
        Favos = JSON.parse(
            localStorage.getItem('Favo') as string
        )
        createFavo = [
            ...Favos,
            ...[Favonew]
        ]
    } else {
        createFavo = [
            ...[Favonew]
        ]
    }
    localStorage.setItem('Favo', JSON.stringify(createFavo))

    setFavi(JSON.stringify(createFavo))


    let oldStar = document.getElementById('Holder'+Favonew.name.replace(/ /g,'')) as HTMLSpanElement;

    oldStar.innerHTML = '';

    let newElement =  <>
                <span id={Favonew.icon} className="material-icons" aria-hidden="true" style={{color:'gold',position: 'absolute',
                    top: '5px',right: '5px'}} title={Favonew.name} onClick={()=> DeleteFavo(Favonew,setFavi)}>star</span>
    </>
    let temp = document.createElement('span')
    ReactDOM.render(newElement, temp)
    if (oldStar !== null) {
        oldStar.appendChild(temp)
    }
}


export const DeleteFavo = (favoDeleted:any,setFavi:any) => {

    let FavoNew = [];

    let Favos = JSON.parse(
        localStorage.getItem('Favo') as string
    )
    for(let f=0; f<Favos.length; f++) {

        if (Favos[f].name === favoDeleted.name) {
            delete Favos[f]
        }

    }
    for(let f=0; f<Favos.length; f++) {
        if(Favos[f] !== null && Favos[f] !== undefined) {
            FavoNew.push(Favos[f])
        }
    }

    localStorage.setItem('Favo', JSON.stringify(FavoNew))
    setFavi(JSON.stringify(FavoNew))
    let oldStar = document.getElementById('Holder'+favoDeleted.name.replace(/ /g,'')) as HTMLSpanElement;

    oldStar.innerHTML = '';

    let newElement =  <>
              <span id={favoDeleted.icon} className="material-icons" aria-hidden="true" style={{color:'white',position: 'absolute',
                  top: '5px',right: '5px'}} title={favoDeleted.name} onClick={()=> SetFavo(favoDeleted,setFavi)}>star_border</span>
    </>
    let temp = document.createElement('span')
    ReactDOM.render(newElement, temp)
    if (oldStar !== null) {
        oldStar.appendChild(temp)
    }
}
