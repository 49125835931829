import React from 'react'
import Todo from './todo'
import CommentsMaker from './commentsMaker'
import '../../css/notificationmaker.css'
import NotificationMakerProps from '../../Interface/notificationMaker.interface'

const NotificationMaker = (props: NotificationMakerProps) => {
    return (
        <>
            <p className="margright">
                <hr style={{ margin: '5px' }} />
                |
                <Todo todo={props.todo} />
                <CommentsMaker comments={props.comments} />
            </p>
        </>
    )
}

export default NotificationMaker
