import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import { buildDoc } from '../../../../@WUM/core/component/const/download'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import InputGroup from 'react-bootstrap/InputGroup'
import FormUploadFile from '../../../../@WUM/core/form-serilizer/elements/FormUploadFile'

const Forms = (
    response: any = [],
    mode: any = 'new',
    fetch: any = [],
    fetch2: any = []
) => {
    let BuisnessKeys = fetch

    if (BuisnessKeys.length > 0) {
        return (
            <>
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={'Allgemein'}
                        key={'allgemein'}
                        id={'allgemein'}
                    >
                        <h3>
                            Bereitstellung von Daten für Gründung einer s.r.o.
                            <br />
                            Ihre Angaben werden streng vertraulich behandelt
                        </h3>
                        <p>
                            Bitte tragen Sie die erforderlichen Daten in die
                            nachfolgende Tabelle in die zugehörigen Feldern ein.
                        </p>
                        <h4>Persönlichen Daten</h4>
                        <hr />
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="gffirstname"
                                >
                                    <Form.Label>Vorname:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'firstname'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].first_name
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="gflastname"
                                >
                                    <Form.Label>Nachname</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'lastname'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].last_name
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="street">
                                    <Form.Label>Straße:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'street'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].street
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="postcode"
                                >
                                    <Form.Label>Postleitzahl</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'postcode'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].postcode
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>Ort</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'city'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].city
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="dob">
                                    <Form.Label>Geburtstag</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name={'dob'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].dob
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="pob">
                                    <Form.Label>Geburtsort</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'pob'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].pob
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="nationality"
                                >
                                    <Form.Label>Nationalität:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'nationality'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].nationality
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="taxId">
                                    <Form.Label>
                                        Persönliche Steueridentifikationsnummer:
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'taxId'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].taxId
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="mobil">
                                    <Form.Label>Handynummer:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'mobil'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].mobil
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>E-Mail:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name={'email'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].email
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h4>Daten zur Firma</h4>
                        <hr />
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="companyPlace"
                                >
                                    <Form.Label>
                                        gewünschter Firmensitz
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'companyPlace'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].companyPlace
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="companyName"
                                >
                                    <Form.Label>
                                        gewünschter Firmenname
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'companyName'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].companyName
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="shareCapital"
                                >
                                    <Form.Label>
                                        Höhe des Stammkapitals in CZK:
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'shareCapital'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].shareCapital
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromList"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-1
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromList'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromList.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListSecond"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-2
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListSecond'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListSecond.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-3
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListThird'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListThird.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-4
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListFourth'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListFourth.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-5
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListFifth'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListFifth.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-6
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListSixth'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListSixth.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-7
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListSeventh'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListSeventh.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-8
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListEighth'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListEighth.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-9
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListNinth'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListNinth.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="businessNumberFromListThird"
                                >
                                    <Form.Label>
                                        Tätigkeitsnummer laut Liste-10
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'businessNumberFromListTenth'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].businessNumberFromListTenth.split('-')[0] as number
                                        }
                                    >
                                        {buildlistEwiv(BuisnessKeys)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="calculatedIncome"
                                >
                                    <Form.Label>
                                        Geplanter Umsatz im 1. und 2. Jahr
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'calculatedIncome'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].calculatedIncome
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="calculatedEmploye"
                                >
                                    <Form.Label>
                                        Geplante Mitarbeiterzahl
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'calculatedEmploye'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].calculatedEmploye
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h4>Sonstiges</h4>
                        <hr />
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="paymentMehtod"
                                >
                                    <Form.Label>
                                        gewünschte Zahlweise
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'paymentMehtod'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].paymentMehtod
                                        }
                                    >
                                        <option value={'1'}>
                                            järhlich vorab
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <h4>Rechnungsanschrift</h4>
                        <hr />
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="invoiceAddress"
                                >
                                    <Form.Label>Straße</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'invoiceAddress'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].invoiceAddress
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="invoicePostcode"
                                >
                                    <Form.Label>PLZ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'invoicePostcode'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].invoicePostcode
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="invoiceCity"
                                >
                                    <Form.Label>Ort</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'invoiceCity'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].invoiceCity
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="invoiceTax"
                                >
                                    <Form.Label>
                                        Umsatzsteuer-Identifikationsnummer /
                                        Steuernummer
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'invoiceTax'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].invoiceTax
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>{' '}
                    </Tab>
                    <Tab
                        eventKey="Dokumente"
                        title={'Dokumente'}
                        key={'Dokumente'}
                        id={'Dokumente'}
                    >
                        <h2>Dokumente</h2>
                        {mode === 'new' ? (
                            <></>
                        ) : (
                            <Row>
                                <Col className={'col-6'}>
                                    <br />
                                    <Form.Group
                                        className="mb-3"
                                        controlId="form"
                                    >
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">
                                                Art auswählen:
                                            </InputGroup.Text>
                                            <Form.Select
                                                id={'field_context'}
                                                aria-label="Default select example"
                                                name={'form'}
                                                onClick={(e) =>
                                                    unlock(e, 'sroupload')
                                                }
                                            >
                                                <option />
                                                <option value={'privacy'}>
                                                    Datenschutz
                                                </option>
                                                <option value={'service'}>
                                                    Dienstleistungauftrag
                                                </option>
                                                <option value={'perso'}>
                                                    Personalausweiss
                                                </option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col className={'col-6'}>
                                    {' '}
                                    <FormUploadFile
                                        data={{
                                            formType: 'formFileBucket',
                                            onclick: true,
                                            onclick_context:
                                                'pielers_element_text_image',
                                            append: 'preview_media',
                                            experiment: true,
                                            multi: false,
                                            fieldcontext: 'field_context',
                                            src: 'ewiv',
                                            addedRoute: 'ewiv/',
                                            Buckettype: true,
                                            send_id: response[0].id,
                                            id: 'sroupload',
                                            url: 'sro/',
                                            type: 'file',
                                            size: {
                                                xl: 10,
                                                md: 10,
                                                sm: 10
                                            }
                                        }}
                                        Store={'de'}
                                    />
                                </Col>
                            </Row>
                        )}
                        {mode !== 'new' ? (
                            response[0].medias !== null &&
                            response[0].medias !== undefined ? (
                                buildDoc(response[0].medias)
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                    </Tab>
                </Tabs>
            </>
        )
    } else {
        return <></>
    }
}

export default Forms

const buildlistEwiv = (BuisnessKeys: any) => {
    let option = []
    for (let x = 0; x < BuisnessKeys.length; x++) {
        option.push(
            <>
                <option value={BuisnessKeys[x].key}>
                    {BuisnessKeys[x].key} - {BuisnessKeys[x].value}
                </option>
            </>
        )
    }

    return option
}

const unlock = (e: any, name: any) => {
    let fileField = document.getElementById(name) as HTMLInputElement
    if (
        e.target.value !== undefined &&
        e.target.value !== null &&
        e.target.value !== ''
    ) {
        fileField.removeAttribute('disabled')
    } else {
        fileField.setAttribute('disabled', 'true')
    }
}
