import React from 'react'
import Ticketoverview from './Pages/overview'
import Issue from './Pages/issue'
import NewIssue from './Pages/newIssue'
import EditIssue from './Pages/editIssue'
import LabelEdit from './Pages/LabelEdit'
import { LanguageSwitcherValue } from './components/LanguageSwitcher'

export const TicketPagesConfig = (value: any) => {
    return [
        {
            path: '/ticket/overview/:page',
            elements: <Ticketoverview value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/ticket/Issue/:id',
            elements: <Issue value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/ticket/edit/Issue/:id',
            elements: <EditIssue value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/ticket/new/Issue',
            elements: <NewIssue value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/Label/ticket/overview',
            elements: <LabelEdit value={value} />,
            roles: ['superadmin', 'admin']
        }
    ]
}

export const TicketMenuConfig = (value: any) => {
    return [
        {
            header: LanguageSwitcherValue('menu.header'),
            icon: 'support',
            roles: ['superadmin', 'admin'],
            menuitem: [
                {
                    name: LanguageSwitcherValue('menu.item.dashboard'),
                    route: '/ticket/overview/:page',
                    icon: 'developer_board',
                    roles: ['superadmin', 'admin']
                },
                {
                    name: LanguageSwitcherValue('menu.item.labels'),
                    route: '/Label/ticket/overview',
                    icon: 'label',
                    roles: ['superadmin', 'admin']
                }
            ]
        }
    ]
}

export const itSupport = () => [
    {
        title: 'menu.item.dashboard',
        href: '/ticket/overview/1',
        undertitle: 'IT-Support',
        icon: 'developer_board',
        text: 'menu.item.dashboard.description',
        roles: ['superadmin', 'admin']
    },
    {
        title: 'menu.item.labels',
        href: '/Label/ticket/overview',
        undertitle: 'IT-Support',
        icon: 'label',
        text: 'menu.item.labels.description',
        roles: ['superadmin', 'admin']
    }
]

export const itSupportSidebar: string =
    '/ticket/overview/:page,/Label/ticket/overview'
