import React from 'react'
import { LanguageSwitcherValue } from './LanguageSwitcher'
import '../../css/commentsMaker.css'
import CommentsMakerProps from '../../Interface/commentsMaker.interface'

const CommentsMaker = (props: CommentsMakerProps) => {
    let commentsHolder = 0
    if (props.comments !== undefined && props.comments !== null) {
        commentsHolder = props.comments.length
    }
    let TitleComments =
        commentsHolder === 1
            ? commentsHolder + '' + LanguageSwitcherValue('common.comment')
            : commentsHolder + '' + LanguageSwitcherValue('common.comments')
    return (
        <>
            <span title={TitleComments} className="material-icons colorvert">
                comment
            </span>
            {commentsHolder} |
        </>
    )
}

export default CommentsMaker
