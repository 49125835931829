import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { buildDoc } from '../../../../@WUM/core/component/const/download'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import FormUploadFile from '../../../../@WUM/core/form-serilizer/elements/FormUploadFile'

const Forms = (response: any = [], mode: any = 'new', fetch: any = []) => {
    let Country = fetch

    if (Country.length > 0) {
        return (
            <>
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={'Allgemein'}
                        key={'allgemein'}
                        id={'allgemein'}
                    >
                        <h3>Ausländisch</h3>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="company"
                                >
                                    <Form.Label>Firmenname:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'company'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].company
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="hrb">
                                    <Form.Label>
                                        Handelsregister und HR-Nummer:
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'hrb'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].hrb
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="gf">
                                    <Form.Label>Geschäftsführer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'gf'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new' ? '' : response[0].gf
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="street">
                                    <Form.Label>Straße:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'street'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].street
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="postcode"
                                >
                                    <Form.Label>Postleitzahl</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'postcode'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].postcode
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>Ort</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={'city'}
                                        required={true}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].city
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>Land</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name={'country'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].country
                                        }
                                    >
                                        {buildCounty(Country)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="buisness"
                                >
                                    <Form.Label>
                                        Welche Tätigkeit/ Aufgaben erfüllt Ihr
                                        deutsches Unternehmen?{' '}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name={'buisness'}
                                        rows={5}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].buisness
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab
                        eventKey="Dokumente"
                        title={'Dokumente'}
                        key={'Dokumente'}
                        id={'Dokumente'}
                    >
                        <h2>Dokumente</h2>
                        {mode === 'new' ? (
                            <></>
                        ) : (
                            <Row>
                                <Col className={'col-6'}>
                                    <br />
                                    <Form.Group
                                        className="mb-3"
                                        controlId="form"
                                    >
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">
                                                Art auswählen:
                                            </InputGroup.Text>
                                            <Form.Select
                                                id={'field_context'}
                                                aria-label="Default select example"
                                                name={'form'}
                                                onClick={(e) =>
                                                    unlock(e, 'foreignupload')
                                                }
                                            >
                                                <option />
                                                <option value={'doc'}>
                                                    Dokument
                                                </option>
                                                <option value={'hr'}>
                                                    Handelsregister
                                                </option>
                                                <option value={'privacy'}>
                                                    Datenschutz
                                                </option>
                                                <option value={'service'}>
                                                    Dienstleistungauftrag
                                                </option>
                                                <option value={'perso'}>
                                                    Personalausweis
                                                </option>
                                                <option value={'extra'}>
                                                    Extra
                                                </option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col className={'col-6'}>
                                    {' '}
                                    <FormUploadFile
                                        data={{
                                            formType: 'formFileBucket',
                                            onclick: true,
                                            onclick_context:
                                                'pielers_element_text_image',
                                            append: 'preview_media',
                                            experiment: true,
                                            multi: false,
                                            fieldcontext: 'field_context',
                                            src: 'ewiv',
                                            addedRoute: 'ewiv/',
                                            Buckettype: true,
                                            send_id: response[0].id,
                                            id: 'foreignupload',
                                            url: 'foreign/',
                                            type: 'file',
                                            size: {
                                                xl: 10,
                                                md: 10,
                                                sm: 10
                                            }
                                        }}
                                        Store={'de'}
                                    />
                                </Col>
                            </Row>
                        )}
                        {mode !== 'new' ? (
                            response[0].medias !== null &&
                            response[0].medias !== undefined ? (
                                buildDoc(response[0].medias)
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                    </Tab>
                </Tabs>
            </>
        )
    } else {
        return <></>
    }
}

export default Forms

const buildCounty = (Country: any) => {
    let option = []
    for (let x = 0; x < Country.length; x++) {
        option.push(
            <>
                <option value={Country[x].key}>{Country[x].value}</option>
            </>
        )
    }

    return option
}
const unlock = (e: any, name: any) => {
    let fileField = document.getElementById(name) as HTMLInputElement
    if (
        e.target.value !== undefined &&
        e.target.value !== null &&
        e.target.value !== ''
    ) {
        fileField.removeAttribute('disabled')
    } else {
        fileField.setAttribute('disabled', 'true')
    }
}
