import React from 'react'
import { LanguageSwitcherValue } from '../../../core/components/LanguageSwitcher'
import OrdersDetail from './Pages/OrdersDetail'
import OrdersDetailMercahnt from './Pages/OrdersMerchantDetail'
import OrdersMerchantoverview from './Pages/OrdersMerchantoverview'
import Ordersoverview from './Pages/Ordersoverview'

export const OrdersPagesConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'SHOPwithMatomo':
            return [
                {
                    path: '/Orders/overview/:page',
                    elements: <OrdersMerchantoverview value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Merchant/overview/:page',
                    elements: <OrdersMerchantoverview value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Detail/:id',
                    elements: <OrdersDetail value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Merchant/Detail/:id',
                    elements: <OrdersDetailMercahnt value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                }
            ]
        case 'marketplace':
        case 'marketplacewithMatomo':
            return [
                {
                    path: '/Orders/overview/:page',
                    elements: <Ordersoverview value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Merchant/overview/:page',
                    elements: <OrdersMerchantoverview value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Detail/:id',
                    elements: <OrdersDetail value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Merchant/Detail/:id',
                    elements: <OrdersDetailMercahnt value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                }
            ]
        default:
            return [
                {
                    path: '/Orders/overview/:page',
                    elements: <OrdersMerchantoverview value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Merchant/overview/:page',
                    elements: <OrdersMerchantoverview value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Detail/:id',
                    elements: <OrdersDetail value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                },
                {
                    path: '/Orders/Merchant/Detail/:id',
                    elements: <OrdersDetailMercahnt value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ]
                }
            ]
    }
}

export const OrdersMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('shop.orders.header'),
            route: '/Orders/overview/1',
            icon: 'monetization_on',
            roles: [
                'superadmin',
                'admin',
                'operator',
                'merchant',
                'merchant_ma'
            ]
        }
    ]
}
