import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import React from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Button from 'react-bootstrap/Button'
import IssueBadge from './badge'
import { FormControl, InputGroup } from 'react-bootstrap'
import { saveLabelEdit, saveLabelOverview } from '../../API/saveLabel'
import LanguageSwitcher from './LanguageSwitcher'
import '../../css/laberloverview.css'
import LabeloverviewProps from '../../Interface/Labeloverview.interface'

const Labeloverview = (props: LabeloverviewProps) => {
    const user = {
        displayName: 'Marcel Ugurcu',
        id: 1,
        is_superuser: 1
    }

    let label = props.label
    let row = []

    function openEdit(bg: any, tg: any, text: any, id: any) {
        let idField = document.getElementById('id') as HTMLInputElement
        idField.value = id

        let bgField = document.getElementById('bgField') as HTMLInputElement
        bgField.value = bg

        let textField = document.getElementById('textField') as HTMLInputElement
        textField.value = text

        let tgField = document.getElementById('tgField') as HTMLInputElement
        tgField.value = tg

        // @ts-ignore
        document.getElementById('editpreviewBadge').style.background = bg

        // @ts-ignore
        document.getElementById('editpreviewBadge').innerHTML = text

        // @ts-ignore
        document.getElementById('editpreviewBadge').style.color = tg

        // @ts-ignore
        document.getElementById('Content').style.display = 'block'
        // @ts-ignore
        document.getElementById('editLabel').style.display = 'block'
    }

    function openNew() {
        // @ts-ignore
        document.getElementById('Content').style.display = 'block'
        // @ts-ignore
        document.getElementById('newLabel').style.display = 'block'
    }

    function changeBG(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        elementID: any
    ) {
        // @ts-ignore
        document.getElementById(elementID).style.background = e.target.value
    }

    function changeText(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        elementID: any
    ) {
        // @ts-ignore
        document.getElementById(elementID).innerHTML = e.target.value
    }

    function changeTG(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        elementID: any
    ) {
        // @ts-ignore
        document.getElementById(elementID).style.color = e.target.value
    }

    const closeedit = () => {
        // @ts-ignore
        document.getElementById('Content').style.display = 'none'
        // @ts-ignore
        document.getElementById('editLabel').style.display = 'none'
    }

    const closenew = () => {
        // @ts-ignore
        document.getElementById('Content').style.display = 'none'
        // @ts-ignore
        document.getElementById('newLabel').style.display = 'none'
    }
    if (user.is_superuser !== 1) {
        return (
            <>
                <h1>
                    <LanguageSwitcher messageId={'401.not.accept'} />
                </h1>
            </>
        )
    } else {
        for (let x = 0; x < label.length; x++) {
            row.push(
                <>
                    <TableRow>
                        <TableCell>{label[x].id}</TableCell>
                        <TableCell>
                            <div
                                id={'BgBox' + label[x].id}
                                className={'LabelColorBox'}
                                style={{ backgroundColor: label[x].bg }}
                            />
                        </TableCell>
                        <TableCell id={'text' + label[x].id}>
                            {label[x].text}
                        </TableCell>
                        <TableCell>
                            <div
                                id={'tgbox' + label[x].id}
                                className={'LabelColorBox'}
                                style={{ backgroundColor: label[x].tg }}
                            />
                        </TableCell>
                        <TableCell>
                            <IssueBadge
                                bg={label[x].bg}
                                tg={label.tg}
                                text={label[x].text}
                                id={label[x].id}
                            />
                        </TableCell>
                        <TableCell>
                            <Button
                                variant="success"
                                onClick={() =>
                                    openEdit(
                                        label[x].bg,
                                        label[x].tg,
                                        label[x].text,
                                        label[x].id
                                    )
                                }
                            >
                                Bearbeiten
                            </Button>
                        </TableCell>
                        {/*<TableCell>
            <Button variant="danger">Löschen</Button>
          </TableCell>*/}
                    </TableRow>
                </>
            )
        }

        return (
            <div className="whitepad">
                <Button
                    className={'float-end'}
                    variant="success"
                    onClick={() => openNew()}
                >
                    Neues Label erstellen
                </Button>
                <br style={{ clear: 'both' }} />
                <br />
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Background Color</TableCell>
                                <TableCell>Text</TableCell>
                                <TableCell>TextColor</TableCell>
                                <TableCell>Preview</TableCell>
                                <TableCell></TableCell>
                                {/*<TableCell></TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody id={'LabelBody'}>{row}</TableBody>
                    </Table>
                </Paper>
                <div
                    id={'Content'}
                    className={'modalLabeledit'}
                    style={{ display: 'none' }}
                >
                    <div id={'editLabel'} className={'hidden'}>
                        <form onSubmit={(e) => saveLabelEdit(e, props.Store)}>
                            <FormControl
                                aria-label="bg"
                                name={'id'}
                                id={'id'}
                                type={'hidden'}
                                aria-describedby="basic-addon0"
                            />
                            <InputGroup className="mb-3">
                                <InputGroup.Text
                                    id="basic-addon1"
                                    title={'Background Color'}
                                >
                                    BG
                                </InputGroup.Text>
                                <FormControl
                                    aria-label="bg"
                                    name={'bg'}
                                    id={'bgField'}
                                    type={'color'}
                                    aria-describedby="basic-addon1"
                                    onChange={(e) =>
                                        changeBG(e, 'editpreviewBadge')
                                    }
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                    Text
                                </InputGroup.Text>
                                <FormControl
                                    placeholder={'Preview'}
                                    aria-label="text"
                                    name={'text'}
                                    id={'textField'}
                                    type={'text'}
                                    aria-describedby="basic-addon2"
                                    onChange={(e) =>
                                        changeText(e, 'editpreviewBadge')
                                    }
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text
                                    id="basic-addon3"
                                    title={'Text Color'}
                                >
                                    TG
                                </InputGroup.Text>
                                <FormControl
                                    aria-label="tg"
                                    name={'tg'}
                                    id={'tgField'}
                                    type={'color'}
                                    aria-describedby="basic-addon3"
                                    onChange={(e) =>
                                        changeTG(e, 'editpreviewBadge')
                                    }
                                />
                            </InputGroup>
                            <div id={'editpreview'}>
                                <span
                                    id={'editpreviewBadge'}
                                    className="m-1 PielersBadge rounded-pill"
                                    style={{
                                        backgroundColor: 'rgb(26, 25, 25)',
                                        color: '#fff'
                                    }}
                                >
                                    Preview
                                </span>
                            </div>
                            <br />
                            <Button
                                type={'submit'}
                                variant={'success'}
                                className={'mb10'}
                            >
                                Speichern
                            </Button>
                            <Button
                                type={'button'}
                                className={'float-end'}
                                onClick={() => closeedit()}
                            >
                                Abbrechen
                            </Button>
                            <br />
                        </form>
                    </div>
                    <div id={'newLabel'} className={'hidden'}>
                        <form
                            onSubmit={(e) =>
                                saveLabelOverview(e, '0', props.Store)
                            }
                        >
                            <InputGroup className="mb-3">
                                <InputGroup.Text
                                    id="basic-addon1"
                                    title={'Background Color'}
                                >
                                    BG
                                </InputGroup.Text>
                                <FormControl
                                    aria-label="bg"
                                    name={'bg'}
                                    type={'color'}
                                    aria-describedby="basic-addon1"
                                    onChange={(e) =>
                                        changeBG(e, 'previewBadge')
                                    }
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                    Text
                                </InputGroup.Text>
                                <FormControl
                                    placeholder={'Preview'}
                                    aria-label="text"
                                    name={'text'}
                                    type={'text'}
                                    aria-describedby="basic-addon2"
                                    onChange={(e) =>
                                        changeText(e, 'previewBadge')
                                    }
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text
                                    id="basic-addon3"
                                    title={'Text Color'}
                                >
                                    TG
                                </InputGroup.Text>
                                <FormControl
                                    aria-label="tg"
                                    name={'tg'}
                                    type={'color'}
                                    defaultValue="#ffffff"
                                    aria-describedby="basic-addon3"
                                    onChange={(e) =>
                                        changeTG(e, 'previewBadge')
                                    }
                                />
                            </InputGroup>
                            <div id={'preview'}>
                                <span
                                    id={'previewBadge'}
                                    className="m-1 PielersBadge rounded-pill colors"
                                >
                                    Preview
                                </span>
                            </div>
                            <Button type={'submit'} className={'mb10'}>
                                Speichern
                            </Button>
                            <Button
                                type={'button'}
                                className={'float-end'}
                                onClick={() => closenew()}
                            >
                                Abbrechen
                            </Button>
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Labeloverview
