export const jsonMapping = (value: any) => {

        return {
            username: value.username,
            is_active: value.is_active,
            customer: {
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                phoneNumber: value.phoneNumber
            },
            address: [
                {
                    company: value.company,
                    street: value.street,
                    postcode: value.postcode,
                    city: value.city,
                    country_code: value.country_code
                }
            ],
            provision: value.provision,
            billingsInformation: {
                company: value.billing_company,
                street: value.billing_street,
                postcode: value.billing_postcode,
                city: value.billing_city,
                country_code: value.billing_country_code,
                Kontoinhaber: value.Kontoinhaber,
                IBAN: value.IBAN,
                BIC: value.BIC
            }
        }
}


export const jsonMappingEdit = (value: any) => {

        return {
            username: value.username,
            is_active: value.is_active,
            id: value.userId,
            customer: {
                id: value.customer_id,
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                phoneNumber: value.phoneNumber
            },
            address: [
                {
                    id: value.address_id,
                    company: value.company,
                    street: value.street,
                    postcode: value.postcode,
                    city: value.city,
                    country_code: value.country_code
                }
            ],
            provision: value.provision,
            billingsInformation: {
                userId: value.userId,
                company: value.billing_company,
                street: value.billing_street,
                postcode: value.billing_postcode,
                city: value.billing_city,
                country_code: value.billing_country_code,
                Kontoinhaber: value.Kontoinhaber,
                IBAN: value.IBAN,
                BIC: value.BIC
            }
        }
}
