import React from 'react'
import { LanguageSwitcherValue } from './LanguageSwitcher'
import '../../css/todo.css'
import TodoProps from '../../Interface/todo.interface'

const Todo = (props: TodoProps) => {
    let todoHolder = 0
    if (props.todo !== undefined) {
        todoHolder = props.todo.length
    }
    let TitleTodo =
        todoHolder === 1
            ? todoHolder + '' + LanguageSwitcherValue('common.todo')
            : todoHolder + '' + LanguageSwitcherValue('common.todos')
    return (
        <>
            <span title={TitleTodo} className="material-icons colorvert">
                list_alt
            </span>
            {todoHolder} |
        </>
    )
}

export default Todo
