import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { siteApiDetailType } from '../../API/request/site/siteApiDetail.interface'
import { useLocalStorage } from '../../../../../@WUM/core/component/const/useLocalStoreage'
import { SpinnerJSX } from '../../../../../@WUM/core/component/const/Spinner'
import PageDetailInterface from '../../interface/PageDetail.interface'
import updateData from '../../../../../@WUM/core/component/core/components/updateData'
import '../../css/style.css'
import { site } from '../../API/request/site/site'
import FormCMS from '../../../../../@WUM/core/component/core/components/Form.cms'
import PagesDetailTabsConfig from '../configuration/PagesDetailTabs.config'

//redux
import * as actions from '../../../../../store/Actions/'
import { useSelector, useDispatch } from 'react-redux'
import {
    ResponseDetailSite,
    ResponseFooter,
    ResponseHeader
} from '../../API/response/site/site'
import { ResponseformularALL } from '../../../Formular/API/response/formular/formular'
import { ResponseallProducts } from '../../../../shop/products/API/response/product/Product'

const PagesDetail = (props: PageDetailInterface) => {
    const dispatch = useDispatch()
    const Store = useSelector((state: any) => state)
    ResponseformularALL()
    ResponseallProducts()
    ResponseHeader()
    ResponseFooter()
    const { id } = useParams<{ id: string }>()
    // redux call new code
    useEffect(() => {
        if (id) {
            dispatch(actions.singlePageDetailsID(id) as any) // single page detail
        }
    }, [dispatch, id])
    //
    //let response2 = useSelector((state: any) => state.page.singlePage) // data call from redux
    let response2 = ResponseDetailSite(id) // old data callmeins

    const [currentTutorial, setCurrentTutorial] =
        React.useState<siteApiDetailType[]>(response2)
    const [element, setElement] = React.useState([])
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    const [seo_title, setseo_title] = useLocalStorage('seo_title', '')
    const [seo_description, setseo_description] = useLocalStorage(
        'seo_description',
        ''
    )
    const [deleteElement, setdeleteElement] = React.useState(0)
    const history = useNavigate()
    const [Index, setIndex] = React.useState(0)
    const [validated, setValidated] = React.useState(false)

    const debug = (deleteElement: number) => {
        return
    }

    debug(deleteElement)

    if (response2.length > 0) {
        return (
            <>
                <FormCMS
                    history={history}
                    element={element}
                    setElement={setElement}
                    id={id}
                    Index={Index}
                    setIndex={setIndex}
                    currentTutorial={currentTutorial}
                    modalData={modalData}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    setModalData={setModalData}
                    seo_title={seo_title}
                    setseo_title={setseo_title}
                    setseo_description={setseo_description}
                    seo_description={seo_description}
                    response2={response2}
                    part={'site'}
                    setdeleteElement={setdeleteElement}
                    setValidated={setValidated}
                    validated={validated}
                    NowRoute={site}
                    setCurrentTutorial={setCurrentTutorial}
                    updateData={updateData}
                    config={PagesDetailTabsConfig}
                    Store={Store}
                    back={'/Pages/overview/1'}
                />
            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PagesDetail
