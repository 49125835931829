/**
 * @typedef SpinnerJSXProps
 * @prop {string} messageId ID des Language Key
 * @prop {string} display Falls der Display Status vorab anders gesetzt werden soll
 * @prop {string} class Falls zusätzliche Klassen benötigt werden
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
import { Spinner } from 'react-bootstrap'
import LanguageSwitcher from './LanguageSwitcher'
import React from 'react'

interface SpinnerJSXProps {
    messageId: string
    display?: string
    class?: string
}
/**
 * SpinnerJSX
 * @component
 * @example
 * <SpinnerJSX messageId={'common.loading'} />
 * @param {SpinnerJSXProps} props Übergabe werte zum Füllen des Spinners
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const SpinnerJSX: React.FC<SpinnerJSXProps> = (props) => {
    return (
        <>
            <div
                className={'scrollbar-overlay spinner' + props.class}
                style={{ display: props.display }}
            >
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>
                    <LanguageSwitcher messageId={props.messageId} />{' '}
                </p>
            </div>
        </>
    )
}
