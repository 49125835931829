import React from 'react'
import Login from '../modules/Login'
import { notify_token_exp } from '../../../../core/component/const/notification.service'
import { fetchDataApiUser } from '../../../../core/RestFullApi/ApiHook'
import * as actions from '../store/actions/User/UserAction'
import { AppDispatch } from '../../../../../store'
import { useDispatch, useSelector } from 'react-redux'
import Signin from '../modules/Signin'
import ForgottPassword from "../modules/ForgottPassword";
import SetPassword from "../modules/SetPassword";

const AuthService = () => {
    let token = localStorage.getItem('token')
    if (!token && window.location.pathname !== '/login') {
        if (!token && window.location.pathname !== '/Signin') {
            if (!token && window.location.pathname === '/forgottpassword') {
                return (
                    <>
                        <ForgottPassword value={'de'} />
                    </>
                ) }
                else if(!token && window.location.pathname.includes('/passwort-new') === true) {
                return (
                    <>
                        <SetPassword value={'de'} />
                    </>
                )

            } else {
                return (
                    <>
                        <Login value={'de'}/>
                    </>
                )
            }
        } else {
                return (
                    <>
                        <Signin value={'de'}/>
                    </>
                )
        }
    } else {
        localStorage.setItem('Logout', '2')
        return
    }
}

export default AuthService

export const AuthGateService = () => {
    let token = localStorage.getItem('token')
    if (!token && window.location.pathname !== '/login') {
        // Login Deaktiviert
        return false
    } else {
        localStorage.removeItem('clearStore')
        localStorage.removeItem('checkLoad')
        return true
    }
}

export const checkLogin = (data: any) => {
    /*    if(data.length !== undefined && data.length !== null) {
        if (data[0].hasOwnProperty('error') && data[0].error !== undefined && data[0].error !== null && data[0].error === 1) {
            notify_token_exp();
            localStorage.removeItem('token');
            AuthService();
            setTimeout(() => window.location.reload(), 3000);
        }
    }*/
}

export const checkLoginMakeLogout = (data: any) => {
    if (data.length !== undefined && data.length !== null && data.length > 0) {
        if (
            data[0].hasOwnProperty('error') &&
            data[0].error !== undefined &&
            data[0].error !== null &&
            data[0].error === 1
        ) {
            notify_token_exp()
            localStorage.removeItem('token')
            AuthService()
            logout()
            return false
        } else {
            localStorage.setItem('Logout', '2')
            return true
        }
    } else {
        notify_token_exp()
        localStorage.removeItem('token')
        AuthService()
        logout()
        return false
    }
}

export const logout = () => {
    localStorage.clear()
    localStorage.removeItem('token')
    window.location.reload()
}

export const AuthRoute = () => {
    let Store = useSelector((state: any) => state)

    const dispatch: AppDispatch = useDispatch()
    let raw
    let token: string
    if (localStorage.getItem('Logout') !== null) {
        if (
            localStorage.getItem('email') !== null ||
            Store.user.email !== null
        ) {
            raw = JSON.stringify({
                email:
                    localStorage.getItem('email') !== null
                        ? localStorage.getItem('email')
                        : Store.user.email
            })
            token = (localStorage.getItem('token') as string).replaceAll(
                '"',
                ''
            )
        } else {
            token = ''
            raw = JSON.stringify({
                email: 'demo@demo.de'
            })
        }

        fetchDataApiUser('/token/user/', token, raw)
            .then((datas: any) => {
                if (checkLoginMakeLogout(datas) === true) {
                    if (
                        Store.user.username !== undefined &&
                        Store.user.email !== undefined &&
                        Store.user.username === null &&
                        Store.user.email === null &&
                        Store.user.first_name === '' &&
                        Store.user.last_name === ''
                    ) {
                        dispatch(
                            actions.getUser(
                                datas[0].username as string,
                                datas[0].email as string,
                                datas[0].first_name as string,
                                datas[0].last_name as string,
                                datas[0].is_superuser as number,
                                datas[0].id as number,
                                JSON.parse(datas[0].roles)[1] as string
                            )
                        )
                    }
                }
            })
            .catch((e: any) => {
                //error(e)
                checkLoginMakeLogout(e)
            })

        //console.log(Store)
    }
    return <></>
}
