import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import React from 'react'

const SearchConfig = (destination: any) => {
    return [
        {
            namespace: destination + '_searchValue',
            destination: destination,
            noNewButton: true,
            input: [
                {
                    id: 'search_name',
                    translation: 'Nach Token suchen',
                    param: 'token',
                    method: 'like'
                },
                {
                    id: 'search_name_city',
                    translation: 'Nach Referer Wohnort suchen',
                    param: 'referer.address.city',
                    method: 'like'
                },
                {
                    id: 'search_name_Werber',
                    translation: 'common.date',
                    param: 'referer_id',
                    method: '=',
                    individual: User(
                        destination,
                        'search_name_Werber',
                        'Nach Werber Suchen'
                    )
                },
                {
                    id: 'search_name_User',
                    translation: 'common.date',
                    param: 'user_id',
                    method: '=',
                    individual: User(
                        destination,
                        'search_name_User',
                        'Nach User Suchen'
                    )
                }
            ],
            button: ['common.search', 'common.del.search', 'Neuen User Anlegen']
        }
    ]
}

export default SearchConfig

export const User = (destination: any, id: any, title: any, val: any = id) => {
    let Customer = []
    //console.log(JSON.parse(localStorage.getItem('allUser') as string))
    if (localStorage.getItem('allUser') !== null) {
        let AllCustomer = JSON.parse(localStorage.getItem('allUser') as string)
        //console.log(AllCustomer)
        if (AllCustomer !== undefined && AllCustomer !== null) {
            for (let c = 0; c < AllCustomer.length; c++) {
                if (AllCustomer[c] !== null && AllCustomer[c].customer !== null) {
                    if (val === id) {
                        Customer.push(
                            <>
                                <option value={AllCustomer[c].id}>
                                    {AllCustomer[c].customer.firstName}{' '}
                                    {AllCustomer[c].customer.last_name}
                                </option>
                            </>
                        )
                    } else {
                        Customer.push(
                            <>
                                <option
                                    value={
                                        AllCustomer[c].customer.firstName +
                                        ' ' +
                                        AllCustomer[c].customer.last_name
                                    }
                                >
                                    {AllCustomer[c].customer.firstName}{' '}
                                    {AllCustomer[c].customer.last_name}
                                </option>
                            </>
                        )
                    }
                }
            }
        }
    }

    return (
        <Form.Group as={Col} controlId={id} name={id}>
            <Form.Label>{title}</Form.Label>
            <Form.Select
                aria-label="Filter"
                defaultValue={
                    localStorage.getItem(destination + '_' + id) as string
                }
            >
                <option />
                {Customer}
            </Form.Select>{' '}
        </Form.Group>
    )
}
