import Card from 'react-bootstrap/Card'
import IntlMessage, { IntlMessageValue } from './IntlMessage'
import Button from 'react-bootstrap/Button'
import '../css/dashboardCards.css'
import { TooltipIcon } from './Tooltip'
import React from 'react'

/**
 * <Cards title={'dashboard.care_services_by_year.header'}
 * href={'/CareServiceSettlement/overview/1'}
 * text={'dashboard.care_services_by_year.description'}
 * Store={Store} />
 * @param props
 * @constructor
 */
const Cards = (props: {
    title: any
    href: any
    text: any
    Store: any
    loginIcon?: any
    icon?: any
    undertitle?: any
    withIMG?: any
    img_path?: any
    imgpositon?: 'top' | 'bottom'
    buttonText?: string
    onClick?: any
}) => {
    let LoginI =
        props.loginIcon !== undefined && props.loginIcon !== null
            ? props.loginIcon
            : 'login'

    let img = <></>
    if (props.withIMG !== undefined) {
        if (props.imgpositon === undefined) {
            props.imgpositon = 'top'
        }
        img = (
            <>
                <Card.Img variant={props.imgpositon} src={props.img_path} />
            </>
        )
    }

    //let ButtonText = 'common.button.to.overview';

    if (props.buttonText !== undefined) {
        //ButtonText = props.buttonText;
    }

    let button
    if (props.onClick !== undefined) {
        button = (
            <>
                <Button
                    variant="primary"
                    className={'DC_Button'}
                    onClick={() => props.onClick(props.Store)}
                >
                    <span className="material-icons" aria-hidden="true">
                        {LoginI}
                    </span>
                </Button>
            </>
        )
    } else {
        button = (
            <>
                <Button
                    variant="primary"
                    className={'DC_Button'}
                    href={props.href}
                >
                    <span className="material-icons" aria-hidden="true">
                        {LoginI}
                    </span>
                </Button>
            </>
        )
    }

    return (
        <>
            <Card className={'boxShadow DC_Card  w-100'}>
                {img}
                <Card.Body className={'DC_Body'}>
                    <Card.Title
                        className={'DC_Title'}
                        style={{ minHeight: '65px' }}
                    >
                        <IntlMessage
                            Store={props.Store}
                            messageId={props.title}
                        />
                        <TooltipIcon
                            Store={props.Store}
                            locale={''}
                            content={props.text}
                            name={IntlMessageValue(
                                '',
                                props.title,
                                props.Store
                            )}
                            Setclass={'mt-0 d-inline-block float-end ml-2'}
                            mode={true}
                        />
                        <p style={{ fontSize: 'small' }}>
                            {props.undertitle !== undefined
                                ? props.undertitle
                                : ''}
                        </p>
                    </Card.Title>
                    <Card.Text className={'DC_Text'}>
                        <span
                            className="material-icons"
                            aria-hidden="true"
                            style={{ fontSize: '85px' }}
                        >
                            {props.icon}
                        </span>
                    </Card.Text>
                    {button}
                </Card.Body>
            </Card>
            <br />
        </>
    )
}

export default Cards
