import React from 'react'
import templateRegister from './register'
import IntlMessage from '../core/component/const/IntlMessage'
import AuthService, {
    AuthGateService
} from './default/Auth/service/AuthService'

interface TemplateSwitcherProps {
    layout: string
    Store: any
}

const TemplateSwitcher: React.FC<TemplateSwitcherProps> = (props) => {
    let register = templateRegister()
    let template: JSX.Element = <></>

    register.map((registry, index) => {
        if (!AuthGateService() && process.env.REACT_APP_AUTH === undefined) {
            return (template = <>{AuthService()}</>)
        } else if (registry.template === props.layout) {
            return (template = <>{registry.component}</>)
        } else {
            return (template = (
                <>
                    <p>
                        <IntlMessage
                            Store={props.Store}
                            messageId={'template.dont.found'}
                        />{' '}
                    </p>
                </>
            ))
        }
    })
    return template
}

export default TemplateSwitcher
