import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function table(conf: string, response: any, page: any, Store: any) {
    return {
        tableBody: {
            data: response,
            dataID: 'id',
            visitMenu: 'translation.slug',
            underdata: true,
            keys: [
                'id',
                'username',
                'customer.name',
                'provision',
                'address.0.street',
                'address.0.postcode',
                'address.0.city',
                'customer.phoneNumber'
            ],
            type: [
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string'
            ]
        },
        tableHeader: [
            'ID',
            'Username',
            'Name',
            'Provision',
            'Straße',
            'PLZ',
            'Stadt',
            'Telefon'
        ],
        tableHeaderWidth: [
            '10%',
            '20%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%'
        ],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: [
                '/customer/Detail',
                '/customer/Delete/' + page + '/[ID]'
            ],
            menuParams: ['id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }
}

export default table
