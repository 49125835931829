import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function table(conf: string, response: any, page: any, Store: any) {
    return {
        tableBody: {
            data: response,
            dataID: 'id',
            visitMenu: 'translation.slug',
            underdata: true,
            keys: [
                'id',
                'referer.customer.name',
                'user.customer.name',
                'refferal_date',
                'token',
                'provision'
            ],
            type: ['string', 'string', 'string', 'string', 'string', 'string']
        },
        tableHeader: ['ID', 'Referer', 'User', 'Datum', 'Token', 'Provision'],
        tableHeaderWidth: ['5%', '20%', '20%', '20%', '20%', '15%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: ['/referal/Detail', '/referal/Delete/' + page + '/[ID]'],
            menuParams: ['id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }
}

export default table
