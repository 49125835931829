import React from 'react'

const MenuIcon = (props: { icon: any }) => {
    if (props.icon === undefined || props.icon === '') {
        return <></>
    } else {
        return (
            <span className="material-icons" aria-hidden="true">
                {props.icon}
            </span>
        )
    }
}

export default MenuIcon
