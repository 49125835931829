import { AxiosResponse } from 'axios'
import axios from 'axios'

export const responseBody = (response: AxiosResponse) => response.data

export const configAxios = {
    headers: {
        Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('token') as string
        )}`
    }
}

export const ApiRoot = () => {
    let apiRoot: string | undefined
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        apiRoot = process.env.REACT_APP_API_URL_LOCAL
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        apiRoot = process.env.REACT_APP_API_URL_DEVELOPMENT
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        apiRoot = process.env.REACT_APP_API_URL_LIVE
    }
    return apiRoot
}
const ApiRootCDN = () => {
    let apiRootCDN: string | undefined
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        apiRootCDN = process.env.REACT_APP_CDN_API_URL_LOCAL
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        apiRootCDN = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        apiRootCDN = process.env.REACT_APP_CDN_API_URL_LIVE
    }

    return apiRootCDN
}
export const httpClient = axios.create({
    baseURL: ApiRoot() //YOUR_API_URL HERE
})

export const httpClientWithAuth = axios.create({
    baseURL: ApiRoot(), //YOUR_API_URL HERE
    auth: {
        username: process.env.REACT_APP_AUTH_USERNAME as string,
        password: process.env.REACT_APP_AUTH_PASSWORD as string
    }
})

export const httpClientToken = axios.create({
    baseURL: ApiRoot() //YOUR_API_URL HERE
})

export const httpClientCDN = axios.create({
    baseURL: ApiRootCDN() //YOUR_API_URL HERE
})

export const requests = {
    get: (url: string) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .get(url, configAxios)
            .then(responseBody),
    post: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .post(url, body, configAxios)
            .then(responseBody),
    put: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .put(url, body, configAxios)
            .then(responseBody),
    patch: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .patch(url, body, configAxios)
            .then(responseBody),
    delete: (url: string) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .delete(url, configAxios)
            .then(responseBody)
}

export const requestCDN = {
    get: (url: string) => httpClientCDN.get(url).then(responseBody),
    post: (url: string, body: {}) =>
        httpClientCDN.post(url, body).then(responseBody),
    put: (url: string, body: {}) =>
        httpClientCDN.put(url, body).then(responseBody),
    patch: (url: string, body: {}) =>
        httpClientCDN.patch(url, body).then(responseBody),
    delete: (url: string) => httpClientCDN.delete(url).then(responseBody)
}
