import Issue from './issueMaker'
import React from 'react'
import LanguageSwitcher from './LanguageSwitcher'
import '../../css/buildBoardwithIssue.css'
import BuildBoardwithIssueProps from '../../Interface/buildBoardwithIssue.interface'

const BuildBoardwithIssue = (props: BuildBoardwithIssueProps) => {
    let row = []
    let backlog = []
    let work = []
    let testing = []
    let done = []

    let dummy = props.config[0].issue

    for (let x = 0; x < dummy.length; x++) {
        let Ticket
        if (props.archiv !== true) {
            Ticket = parseInt(dummy[x].close) !== 1 ? dummy : false
        } else {
            Ticket = parseInt(dummy[x].close) === 1 ? dummy : false
        }

        if (Ticket !== false) {
            switch (dummy[x].row.name) {
                case 'backlog':
                    backlog.push({
                        swimelane: dummy[x].swimlanes.name,
                        element: (
                            <>
                                <Issue
                                    card={dummy[x]}
                                    topic={dummy[x].topic}
                                    label={dummy[x].label}
                                />
                                <br />
                            </>
                        )
                    })
                    break
                case 'work':
                    work.push({
                        swimelane: dummy[x].swimlanes.name,
                        element: (
                            <>
                                <Issue
                                    card={dummy[x]}
                                    topic={dummy[x].topic}
                                    label={dummy[x].label}
                                />
                                <br />
                            </>
                        )
                    })
                    break
                case 'testing':
                    testing.push({
                        swimelane: dummy[x].swimlanes.name,
                        element: (
                            <>
                                <Issue
                                    card={dummy[x]}
                                    topic={dummy[x].topic}
                                    label={dummy[x].label}
                                />
                                <br />
                            </>
                        )
                    })
                    break
                case 'done':
                    done.push({
                        swimelane: dummy[x].swimlanes.name,
                        element: (
                            <>
                                <Issue
                                    card={dummy[x]}
                                    topic={dummy[x].topic}
                                    label={dummy[x].label}
                                />
                                <br />
                            </>
                        )
                    })
                    break
            }
        }
    }

    let swimelane = props.config[1].swimelane
    for (let x = 0; x < swimelane.length; x++) {
        row.push(
            <tr>
                <td className="bcolorCenter" colSpan={4} align={'center'}>
                    <LanguageSwitcher
                        messageId={'swimelane.' + swimelane[x].name + '.label'}
                    />
                </td>
            </tr>
        )
        let backlogSwimlane = []
        let workSwimlane = []
        let testingSwimlane = []
        let doneSwimlane = []

        for (let b = 0; b < backlog.length; b++) {
            if (backlog[b].swimelane === swimelane[x].name) {
                backlogSwimlane.push(backlog[b].element)
            }
        }
        for (let w = 0; w < work.length; w++) {
            if (work[w].swimelane === swimelane[x].name) {
                workSwimlane.push(work[w].element)
            }
        }
        for (let t = 0; t < testing.length; t++) {
            if (testing[t].swimelane === swimelane[x].name) {
                testingSwimlane.push(testing[t].element)
            }
        }
        for (let d = 0; d < done.length; d++) {
            if (done[d].swimelane === swimelane[x].name) {
                doneSwimlane.push(done[d].element)
            } else {
                doneSwimlane.push(<></>)
            }
            //console.log(done[d].element)
        }

        row.push(
            <tr>
                <td style={{ width: '25%' }}>{backlogSwimlane}</td>
                <td style={{ width: '25%' }}>{workSwimlane}</td>
                <td style={{ width: '25%' }}>{testingSwimlane}</td>
                <td style={{ width: '25%' }}>{doneSwimlane}</td>
            </tr>
        )
    }

    return <>{row}</>
}

export default BuildBoardwithIssue
