import { CMSMenuConfig } from '../modules/CMS'
import { MatomoMenuConfig } from '../modules/matomo/modules'
import { TicketMenuConfig } from '../modules/ticketsystem/modules'
import { ShopMenuConfig } from '../modules/shop'
import { monitoringMenuConfig } from '../modules/monitoring/modules'
import { SettingsMenuConfig } from '../modules/settings'
import { ShowDialog } from '../@WUM/core/sidebar/sidebar'
import MergeTwoObjecttoArray from '../@WUM/core/component/const/MergeTwoObjecttoArray'
import components from './RegisterComponents'
import { DashbaordMenuConfig } from '../modules/CMS/dashboard/modules'
import { useSelector } from 'react-redux'
import { CalenderMenuConfig } from '../modules/calender'
import { NewsletterMenuConfig } from '../modules/newsletter'
import {checkLocation} from "../@WUM/core/sidebar/ItemMenu";

export const RegisterSitemap = (value: any) => {
    let TMPRegister = []
    let TMPRegisterIndividual:any
    const Store = useSelector((state: any) => state)

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            TMPRegister = [
                ...CMSMenuConfig(value),
                ...CalenderMenuConfig(value),
                ...NewsletterMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
            break
        case 'CMSwithMatomo':
            TMPRegister = [
                ...CMSMenuConfig(value),
                ...CalenderMenuConfig(value),
                ...NewsletterMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
            break
        case 'SHOP':
            TMPRegister = [
                ...CMSMenuConfig(value),
                ...CalenderMenuConfig(value),
                ...NewsletterMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
            break
        case 'SHOPwithMatomo':
            TMPRegister = [
                ...CMSMenuConfig(value),
                ...CalenderMenuConfig(value),
                ...NewsletterMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
            break
        case 'ProductCatalog':
            TMPRegister = [
                ...CMSMenuConfig(value),
                ...CalenderMenuConfig(value),
                ...NewsletterMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
            break
        case 'ProductCatalogwithMatomo':
            TMPRegister = [
                ...CMSMenuConfig(value),
                ...CalenderMenuConfig(value),
                ...NewsletterMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
            break
        default:
            TMPRegister = [
                ...CMSMenuConfig(value),
                ...CalenderMenuConfig(value),
                ...NewsletterMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
    }
    TMPRegisterIndividual = []

    let TMPRegisterSet = []

    if (
        process.env.REACT_APP_DB_MODULS !== undefined &&
        Store.dynamikModuls.MenuModuls.length > 0 &&
        !Store.dynamikModuls.MenuModuls[0].hasOwnProperty('msg')
    ) {
        for (let s = 0; s < Store.dynamikModuls.MenuModuls.length; s++) {

            let tmpMenu = {
                header: '',
                icon: '',
                roles: '',
                menuitem: []
            }

            tmpMenu.header = Store.dynamikModuls.MenuModuls[s].header
            tmpMenu.icon = Store.dynamikModuls.MenuModuls[s].icon
            tmpMenu.roles = Store.dynamikModuls.MenuModuls[s].roles.split(',')
            if(s===0) {
                TMPRegisterIndividual = MergeTwoObjecttoArray(
                    TMPRegisterIndividual,
                    DashbaordMenuConfig(value)
                )
            }
            if (Store.dynamikModuls.MenuModuls[s].menuitem !== undefined && Store.dynamikModuls.MenuModuls[s].channel === process.env.REACT_DYNAMIK_INSTANCE) {
                for (
                    let x = 0;
                    x < Store.dynamikModuls.MenuModuls[s].menuitem.length;
                    x++
                ) {
                    let search = Store.dynamikModuls.MenuModuls[s].menuitem[x]

                    TMPRegisterIndividual = MergeTwoObjecttoArray(
                        TMPRegisterIndividual,
                        /* @ts-ignore */
                        components[search](value)
                    )
                    tmpMenu.menuitem = TMPRegisterIndividual

                }

            }
            TMPRegisterSet.push(tmpMenu)
            TMPRegisterIndividual = []
        }
    }

    if (process.env.REACT_APP_DB_MODULS === undefined) {
        return [...TMPRegister]
    } else {
        if (localStorage.getItem('userRole') === 'superadmin') {
            return [...TMPRegister, ...TMPRegisterSet]
        } else {
            return [...TMPRegisterSet]
        }
    }
}

const Media = (value: any) => [
    {
        header: 'Media',
        icon: 'perm_media',
        roles: ['superadmin', 'admin'],
        menuitem: [
            {
                name: 'Galerie',
                route: '/',
                icon: 'collections',
                onClick: ShowDialog,
                roles: ['superadmin', 'admin']
            }
        ]
    }
]

export const Submenu = (index:any,active:any) => {

    if(active === '') {
        active = window.location.pathname
    }


    return false;

}


export const switchOpen = (identifier:any,active:any) => {
    let check = [];
    for(let x=0;x<identifier.length;x++) {
        if(checkLocation(identifier[x].split('/')[1],active)===true) {
            check.push('true');
        } else {
            check.push('false');
        }
    }
    if(check.includes('true') === true){
        return true;
    } else {
        return false;
    }
}
